@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap");

::-webkit-scrollbar {
  display: none;
}

* {
  font-family: "Poppins", sans-serif;
}

input,
textarea,
select {
  font-size: 16px;
}
